/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useCallback, useMemo } from "react";
import { Stack, Text } from "office-ui-fabric-react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
// import checkRestrictions from "sites-common/utils/checkRestrictions";
import Loader from "react-loader-spinner";
// import FavoritesBar from "./widget/FavoritesBar";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm"; //  DynamicField,
import { compact } from "sites-common/utils/lodash";
import SearchPerson from "./SearchPerson";

import { selectorParticipantsPanel } from "../../state/selectors";
import {
  discardParticipantPage,
  newParticipantPage,
  removeParticipant,
  resetParticipantsPanel,
  submitParticipants,
} from "../../state/actions/participantsPanel";
import {
  P_TO_VALIDATE,
  //  P_VALIDATED,
  P_VALIDATE_ERROR,
  P_VALIDATION_IN_PROGRESS,
} from "../../state/panelsReducer";
import {
  IkonButton,
  HeroButton,
  LowFiButton,
  TransparentButton,
  Notice,
  BadgeLabel,
} from "./StyledButtons";
import AddChild from "./AddChild";
import strAgeGroup from "../../lib/str-age-group";
// import { useFetchSrcmApi } from "../../fetch-srcm-api";

export const DiscardButton = () => {
  const { pageAddNew, submit } = useSelector(selectorParticipantsPanel);

  const dispatch = useDispatch();
  const discardIt = useCallback(() => {
    dispatch(pageAddNew ? discardParticipantPage() : resetParticipantsPanel());
    // dispatch(resetState());
  }, [pageAddNew, dispatch]);
  return (
    <TransparentButton
      disabled={submit}
      iconProps={{ iconName: "Cancel" }}
      onClick={discardIt}
      text="Discard and Go Back"
    />
  );
};

function ProceedToRegisterButton(props) {
  const { persons, submit, keys, numPersons, strings } = useSelector(
    selectorParticipantsPanel
  );
  const verifying =
    Object.values(keys).filter((x) => x[0] === P_TO_VALIDATE).length > 0;
  const errors =
    Object.values(keys).filter((x) => x[0] === P_VALIDATE_ERROR).length > 0;

  const secondaryText = useMemo(() => {
    const children = persons.filter((p) =>
      ["0-4", "0-5", "5-9", "5-10", "10-14", "10-15"].includes(
        strAgeGroup(p.age_group)
      )
    );

    const numChildren = children.length;
    const numAdults = numPersons - numChildren;
    return compact([
      numAdults && `${numAdults} adult${numAdults > 1 ? "s" : ""}`,
      numChildren && `${numChildren} child${numChildren > 1 ? "ren" : ""}`,
    ]).join(" and ");
  }, [persons, numPersons]);

  return (
    <HeroButton
      disabled={verifying || errors || submit}
      iconProps={{ iconName: "Forward" }}
      text={strings.registrationText || `Register ${secondaryText}`}
      {...props}
    />
  );
}

function P2Fields() {
  const { p2fieldmap } = useSelector(selectorParticipantsPanel);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (p2data, onS) => {
      dispatch(submitParticipants(p2data));
      onS();
    },
    [dispatch]
  );

  return (
    <div>
      <Text
        sx={{
          fontSize: "1.2em",
        }}
      >
        Additional Details
      </Text>
      <DynamicForm
        formFields={p2fieldmap.fields}
        formValidator={p2fieldmap.validator}
        onSubmit={onSubmit}
        hideDiscard
        buttons={{ submitButton: <ProceedToRegisterButton type="submit" /> }}
      />
    </div>
  );
}

function ProceedToRegister() {
  const { submit, keys, numPersons, p2fieldmap } = useSelector(
    selectorParticipantsPanel
  );
  const verifying =
    Object.values(keys).filter((x) => x[0] === P_TO_VALIDATE).length > 0;
  const errors =
    Object.values(keys).filter((x) => x[0] === P_VALIDATE_ERROR).length > 0;
  const dispatch = useDispatch();

  const doRegister = useCallback(() => {
    dispatch(submitParticipants());
  }, [dispatch]);

  if (numPersons < 1) {
    return null;
  }

  return (
    <div sx={{ mt: "40px", mb: "10px" }}>
      {!errors && !!p2fieldmap && <P2Fields />}

      {(verifying || submit) && <Loader type="ThreeDots" />}
      {errors && (
        <Notice type="error" mode="multiline">
          Please correct the errors above or delete the erroneous entries to
          proceed
        </Notice>
      )}

      {!errors && !p2fieldmap && (
        <ProceedToRegisterButton onClick={doRegister} />
      )}
    </div>
  );
}

function Person({ person, keyObj, idx, readOnly }) {
  const { styles } = useSelector(selectorParticipantsPanel);

  const dispatch = useDispatch();

  const unselectPerson = useCallback(() => {
    dispatch(removeParticipant(person.key));
  }, [person.key, dispatch]);

  return (
    <div
      sx={{ border: "solid 1px lightblue", backgroundColor: "white", mb: 2 }}
      key={person?.name}
    >
      <Stack horizontalAlign="space-between" horizontal>
        <Stack horizontal sx={{ pl: 2, py: 2 }}>
          <div>
            <Text sx={{ fontSize: "1.3em" }}>{person.name}</Text>
            <div>
              <small>{person.q}</small>
              {person.age_group && (
                <BadgeLabel bg="primary" sx={{ ml: 2 }}>
                  {strAgeGroup(person.age_group)}
                </BadgeLabel>
              )}
              {person.record_type === "t" && (
                <BadgeLabel bg="text" sx={{ ml: 2 }}>
                  trainer
                </BadgeLabel>
              )}
            </div>
          </div>
        </Stack>

        <Stack verticalAlign="space-between">
          <div
            sx={{
              textAlign: "right",
              pr: 2,
              opacity: "0.75",
              fontSize: "1em",
              color: styles.colors.primary,
            }}
          >
            {typeof idx === "number" && idx + 1}
          </div>

          {!readOnly && keyObj[0] !== P_VALIDATION_IN_PROGRESS && (
            <IkonButton
              sx={keyObj[0] === P_VALIDATE_ERROR ? { color: "red" } : {}}
              title="Remove Participant"
              iconProps={{ iconName: "Delete" }}
              onClick={unselectPerson}
            />
          )}
        </Stack>
      </Stack>
      <div>
        {keyObj[0] === P_TO_VALIDATE && <Notice icon="Clock">verifying</Notice>}
        {keyObj[0] === P_VALIDATE_ERROR && (
          <Notice type="error">
            {keyObj[1]}
            &nbsp;-&nbsp;Remove this Participant
          </Notice>
        )}
      </div>
    </div>
  );
}

Person.defaultProps = {
  readOnly: false,
};

Person.propTypes = {
  person: PropTypes.instanceOf(Object).isRequired,
  keyObj: PropTypes.shape([]).isRequired,
  readOnly: PropTypes.bool,
  idx: PropTypes.number.isRequired,
};

const SelectedPersonsList = () => {
  const { persons, submit, keys } = useSelector(selectorParticipantsPanel);

  return (
    <React.Fragment>
      {persons.map((person, idx) => (
        <Person
          person={person}
          idx={idx}
          keyObj={keys[person.key]}
          readOnly={submit}
        />
      ))}
    </React.Fragment>
  );
};

// constraints:
// validatorSinglePerson : (r) => false || "Error String"
// validatorGroup = ([r1, r2, ...]) => false || "Error String"

const ParticipantsPanelGlobal = () => {
  const dispatch = useDispatch();
  const { pageAddNew, submit, numPersons, alert } = useSelector(
    selectorParticipantsPanel
  );

  const openSearchPerson = useCallback(() => {
    dispatch(newParticipantPage());
  }, [dispatch]);

  const openAddChild = useCallback(() => {
    dispatch(newParticipantPage(true));
  }, [dispatch]);

  return (
    <div sx={{ mt: 2 }}>
      {!!alert && (
        <Notice type="warning" mode="multiline">
          {alert.title}
          <br />
          {alert.message}
        </Notice>
      )}
      {/* {pageMain && numPersons < 1 && loading && (
            <Loader type="Oval" color="#b0853a" width={30} height={25} />
          )} */}

      <div>
        <SelectedPersonsList />
        {pageAddNew && !pageAddNew.child && (
          <SearchPerson PersonCard={Person} />
        )}
        {pageAddNew && !!pageAddNew.child && <AddChild />}
        {!pageAddNew && (
          <Stack
            horizontal
            horizontalAlign="start"
            style={{ marginTop: "6px" }}
            tokens={{ childrenGap: 5 }}
          >
            {/* <FavoritesBar style={styles.favouritesBtn} /> */}

            <LowFiButton
              disabled={submit}
              text={numPersons === 0 ? "Add Participant" : "Participant"}
              onClick={openSearchPerson}
              iconProps={{ iconName: "Add" }}
            />
            <LowFiButton
              disabled={submit}
              text="Child"
              onClick={openAddChild}
              iconProps={{ iconName: "Add" }}
            />
          </Stack>
        )}
        {numPersons === 0 && !pageAddNew && (
          <div>
            <br />
            Click on
            <b> Add Participant </b> to register using any of Heartfulness ID
            number / email / mobile number, or click on <b> Add Child </b> for
            registering children.
          </div>
        )}
        {!pageAddNew && numPersons > 0 && (
          <>
            <ProceedToRegister />
            <DiscardButton />
          </>
        )}
      </div>
    </div>
  );
};

// ParticipantsPanelGlobal.propTypes = {
//   onRegistrationCallback: PropTypes.func.isRequired,
//   constraints: PropTypes.instanceOf(Array),
//   registrationText: PropTypes.string,
//   styles: PropTypes.shape({
//     sidePanel: PropTypes.objectOf(PropTypes.any),
//     addPersonBtn: PropTypes.objectOf(PropTypes.any),
//     favouritesBtn: PropTypes.objectOf(PropTypes.any),
//     registerBtn: PropTypes.objectOf(PropTypes.any),
//     discardBtn: PropTypes.objectOf(PropTypes.any),
//     formStyle: PropTypes.objectOf(PropTypes.any),
//   }),

// };

export default ParticipantsPanelGlobal;
