/** @jsx jsx */
import { jsx } from "theme-ui";
import {
  validateAbhyasiId,
  validateEmail,
  // validateConstraints,
} from "@heartfulnessinstitute/react-hfn-forms/dist/validations";

import { Stack } from "office-ui-fabric-react/lib/Stack";
import React, { useState, useCallback, useEffect } from "react";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { useDispatch, useSelector } from "react-redux";
import { validatePhoneNumber } from "sites-common/utils/validations";

import Loader from "react-loader-spinner";
import PropTypes from "prop-types";

import handleError from "sites-common/utils/handleError";
import { useFetchSrcmApi } from "../../fetch-srcm-api";
import { selectorParticipantsPanel } from "../../state/selectors";
import {
  addParticipant,
  discardParticipantPage,
  dismissParticipantAlert,
  // removeParticipant,
} from "../../state/actions/participantsPanel";
import PersonForm from "./PersonForm";
import { Notice, PrimaryButton, TransparentButton } from "./StyledButtons";

const SearchPerson = ({ PersonCard }) => {
  const { persons, keys, styles } = useSelector(selectorParticipantsPanel);
  const dispatch = useDispatch();
  const { fetchSrcmApi } = useFetchSrcmApi();

  const [qStr, setQStr] = useState("");
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState("");

  const [showPersonForm, setShowPersonForm] = useState(false);
  const [abhyasiDetails, setAbhyasiDetails] = useState(null);

  const onDiscard = useCallback(
    () => dispatch(discardParticipantPage()),
    [dispatch]
  );

  let fieldName = "";

  if (qStr) {
    if (validateAbhyasiId(qStr.toUpperCase())) {
      fieldName = "ref";
    } else if (validateEmail(qStr)) {
      fieldName = "email";
    } else if (validatePhoneNumber(qStr)) {
      fieldName = "mobile";
    }
  }
  const valid = !!fieldName;

  const checkInDb = useCallback(
    (q1) => {
      dispatch(dismissParticipantAlert());
      let q = q1;
      if (fieldName === "ref") {
        q = q1.toUpperCase();
      } else if (fieldName === "email") {
        q = q1.toLowerCase();
      } else if (fieldName === "mobile") {
        q = q1;
      } else {
        setError(`Invalid Email or Mobile or Heartfulness Id`);
        return;
      }
      const methodParams = { [fieldName]: q };

      const qStrs = persons.map((p) => (p.q ? p.q : ""));
      // check if it already added to the participants list
      if (qStrs.includes(q)) {
        setError(`Duplicate Entry: ${q} already added`);
        Promise.resolve("").then(setQStr);
        return;
      }

      setSearching(true);
      fetchSrcmApi({
        api: `/api/v2/abhyasis/search/`,
        methodParams,
        client: "eventsClient",
        method: "GET",
      })
        .then((data) => {
          if (data.results.length > 0) {
            const p = addParticipant({ ...data.results[0], q });
            if (p.key in keys) {
              setError(`Duplicate Entry: ${p.person.name} already added`);
              setQStr("");
            } else {
              const isMissingValue = Object.values(p.person).some(
                (value) => !value
              );
              if (isMissingValue) {
                setAbhyasiDetails(p.person);
                setShowPersonForm(true);
              } else {
                setQStr("");
                dispatch(p);
                onDiscard();
              }
            }
            setSearching(false);
          } else {
            if (fieldName === "email" || fieldName === "mobile") {
              setError(
                `Profile with ${
                  fieldName === "email" ? "Email Id" : "Mobile No."
                } ${q} not found. Please enter the following details.`
              );
              setShowPersonForm(true);
            } else {
              setError(
                `Heartfulness Id ${q} not found. Please enter a valid Id.`
              );
            }
            setSearching(false);
          }
        })
        .catch((e) => {
          setError(`Error: ${handleError(e)}`);
          setSearching(false);
        });
    },
    [
      fetchSrcmApi,
      dispatch,
      keys,
      persons,
      setQStr,
      setError,
      setShowPersonForm,
      onDiscard,
      fieldName,
    ]
  );

  useEffect(() => {
    if (qStr) {
      setError("");
      setAbhyasiDetails(null);
      setShowPersonForm(false);
    }
    if (qStr && validateAbhyasiId(qStr)) {
      checkInDb(qStr);
    }
  }, [qStr, checkInDb, setShowPersonForm]);

  const doCheck = useCallback(() => {
    if (valid) {
      checkInDb(qStr);
    }
  }, [valid, qStr, checkInDb]);

  return (
    <div sx={{ bg: styles.colors.well, p: 2 }}>
      {/* <Text variant="small">Search Heartfulness Accounts</Text> */}
      <DynamicField
        label="Heartfulness Id or Email or Mobile"
        name="qStr"
        value={qStr}
        onChange={setQStr}
        readOnly={showPersonForm}
        props={{
          autoFocus: true,
          description:
            "Mobile Format: +919876543211, Heartfulness Id: INXXXA999",
        }}
      />
      {searching && <Loader type="ThreeDots" height={30} />}
      {!searching && (
        <React.Fragment>
          {!!error && (
            <Notice
              sx={{ my: 2 }}
              mode={fieldName !== "ref" ? "multiline" : undefined}
              type={fieldName === "ref" ? "error" : "warning"}
            >
              {error}
            </Notice>
          )}

          {!showPersonForm && (
            <Stack sx={{ mt: 2 }} horizontal horizontalAlign="space-between">
              <PrimaryButton
                iconProps={{ iconName: "Search" }}
                disabled={!valid}
                text="Go!"
                onClick={doCheck}
              />
              <TransparentButton
                text="Discard"
                iconProps={{ iconName: "Cancel" }}
                onClick={onDiscard}
              />
            </Stack>
          )}
        </React.Fragment>
      )}
      {showPersonForm && (
        <React.Fragment>
          <PersonForm
            PersonCard={PersonCard}
            onDiscard={onDiscard}
            fieldName={fieldName}
            fieldValue={qStr}
            abhyasiDetails={abhyasiDetails}
          />
        </React.Fragment>
      )}
    </div>
  );
};

SearchPerson.propTypes = {
  PersonCard: PropTypes.func.isRequired,
};

export default SearchPerson;
