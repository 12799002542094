module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-plugin-hfn-profile/gatsby-browser.js'),
      options: {"plugins":[],"pathToRootReducerModule":"./src/state/appReducer","firebaseAuthConfig":{"apiKey":"AIzaSyDvmxKH7738HE1T3fpJYUvi4BcEAakqQbQ","authDomain":"auth-qa.heartfulness.org","projectId":"unifiedplatform-qa"},"splashScreen":"loading..."},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
