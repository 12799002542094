import React from "react";
import IndiaId from "../PnrSummary/AshramStays/config";

export const isIndian = (country) =>
  typeof country === "string"
    ? country.toLowerCase() === "india"
    : country === IndiaId;

export const additionalFields = [
  {
    name: "citizen_of_country",
    label: "Citizenship",
    type: "srcm_country_id",
  },
  {
    name: "has_oci",
    label: "Overseas Citizen of India",
    type: "toggle",
    defaultValue: false,
    dynamic: (r) => {
      const currentUser = r.citizen_of_country;
      const check = currentUser ? isIndian(currentUser) : true;
      return check
        ? { props: { required: false, readOnly: true }, hide: true }
        : {
            props: {
              required: true,
              readOnly: false,
            },
          };
    },
  },
];

const personFormFields = [
  {
    name: "name",
    label: "Full Name",
    type: "text",
  },
  {
    name: "city_id",
    label: "City / State / Country",
    type: "city",
  },
  {
    name: "age_group",
    label: "Age Group",
    type: "select",
    options: [
      4, 9, 14, 19, 24, 29, 34, 39, 44, 49, 54, 59, 64, 69, 74, 79, 84, 89, 94,
      99,
    ].map((x) => ({ name: x, label: `${x - 4}-${x}` })),
  },
  {
    name: "gender",
    label: "Gender",
    type: "select",
    options: [
      { name: "M", label: "Male", value: "M" },
      { name: "F", label: "Female", value: "F" },
      { name: "U", label: "Unspecified", value: "U" },
    ],
  },
  {
    name: "mobile",
    label: "Mobile",
    type: "phone",
    props: {
      required: false,
      description:
        "Please enter country code along with mobile number. example +918888888888",
    },
  },
  {
    name: "email",
    label: "E-Mail",
    type: "email",
    props: {
      required: false,
    },
  },
  {
    name: "citizen_of_country",
    label: "Citizenship",
    type: "srcm_country_id",
  },
  {
    name: "has_oci",
    label: "Overseas Citizen of India",
    type: "toggle",
    defaultValue: false,
    dynamic: (r) => {
      const currentUser = r.citizen_of_country;
      const check = currentUser ? isIndian(currentUser) : true;
      return check
        ? { props: { required: false }, hide: true }
        : {
            props: {
              required: true,
              description: (
                <div
                  style={{
                    color: "rgb(96, 94, 92)",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    backgroundColor: "beige",
                  }}
                >
                  Overseas visitors are required to submit Form-C, copies of
                  passport and visa upon arrival, as per Indian Government
                  regulations. Exemptions:&nbsp;
                  <a
                    href="https://www.mea.gov.in/Portal/CountryQuickLink/703_PIO-OCI.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Indian passports or OCI cards or PIO cards
                  </a>
                  .
                </div>
              ),
            },
          };
    },
  },
  {
    name: "acknowledgement",
    label:
      "I have taken the consent of the participants before registering and the information submitted is accurate to the best of my knowledge.",
    type: "checkbox",
    props: {
      className: "checkbox_custom",
    },
  },
];

export default personFormFields;
