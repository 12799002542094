/** @jsx jsx */

import { jsx } from "theme-ui";
import { useState, useCallback } from "react";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { uniq } from "sites-common/utils/lodash";
// import { selectorParticipantsPanel } from "../../state/selectors";
import {
  addParticipant,
  discardParticipantPage,
} from "../../state/actions/participantsPanel";
import personFormFields from "./personFormFields";
import { PrimaryButton, TransparentButton } from "./StyledButtons";

let fields = [...personFormFields];
const validateForm = (r, missingFieldsKey) => {
  if (!!r && !!r.mobile) {
    if (!r.mobile.startsWith("+")) {
      return "Mobile number needs to start with +(country code)";
    }
  }

  if (!!r && !r?.acknowledgement) {
    return "Kindly provide the consent before proceeding.";
  }

  if (
    missingFieldsKey.length &&
    missingFieldsKey.includes("mobile") &&
    missingFieldsKey.includes("email")
  ) {
    if (!!r && !r.mobile && !r.email) {
      return "Enter either Mobile or Email";
    }
  }

  return false;
};
function PersonForm({
  onDiscard,
  fieldName,
  fieldValue,
  abhyasiDetails = null,
  PersonCard = null,
}) {
  //  const { keys } = useSelector(selectorParticipantsPanel);
  const dispatch = useDispatch();
  const [data] = useState({ [fieldName]: fieldValue });
  const missingFields = ["has_oci", "citizen_of_country", "acknowledgement"];

  const onSubmit = useCallback(
    (vals, onS) => {
      let newrec = {};
      if (abhyasiDetails) {
        newrec = {
          ...abhyasiDetails,
          ...vals,
          extras: { ...vals },
        };
      } else {
        newrec = {
          ...vals,
          id: uuidv4(),
          ref: "B99999999",
          [fieldName]: fieldValue,
          q: fieldValue,
        };
      }

      Promise.resolve(newrec).then((v) => {
        dispatch(addParticipant(v));
        dispatch(discardParticipantPage());
        onS();
      });
    },
    [fieldName, fieldValue, dispatch, abhyasiDetails]
  );

  if (abhyasiDetails) {
    Object.entries(abhyasiDetails).map(([key, val]) => {
      if (!val) {
        missingFields.push(key);
      }
      return null;
    });

    fields = uniq(
      personFormFields.filter((f) =>
        f.name === "city_id"
          ? missingFields.includes("city")
          : missingFields.includes(f.name)
      ),
      (e) => e.name
    );
  } else {
    fields = [...personFormFields];
  }

  // As per new requirement this has been commented
  // if (!(fieldName === "email" || fieldName === "mobile")) {
  //   return null;
  // }

  return (
    <div sx={{ py: 2 }}>
      {abhyasiDetails && fields.length ? (
        <div sx={{ mb: 2 }}>Please enter the missing details</div>
      ) : null}
      {abhyasiDetails && (
        <PersonCard person={abhyasiDetails} readOnly keyObj="" />
      )}
      <DynamicForm
        newFormLayout={undefined}
        defaultValues={data}
        formFields={fields.filter((f) => f.name !== fieldName)}
        formValidator={(r) => validateForm(r, missingFields)}
        formClassName="person_form_custom"
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        buttons={{
          submitButton: (
            <PrimaryButton
              type="submit"
              iconProps={{ iconName: "Save" }}
              text="Add"
            />
          ),
          discardButton: (
            <TransparentButton
              iconProps={{ iconName: "Cancel" }}
              text="Discard"
            />
          ),
        }}
      />
    </div>
  );
}

PersonForm.propTypes = {
  onDiscard: PropTypes.func.isRequired,
  fieldName: PropTypes.oneOf(["email", "mobile"]).isRequired,
  fieldValue: PropTypes.string.isRequired,
  abhyasiDetails: PropTypes.shape({}).isRequired,
  PersonCard: PropTypes.func.isRequired,
};

export default PersonForm;
