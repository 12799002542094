import { isArray } from "lodash";

function jsonChecker(data) {
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
}

function handleError(vals, defaultMessage = "") {
  try {
    let message = vals.message || vals;
    message = message?.includes?.("Error:")
      ? JSON.parse(message.split("Error: ")[1])
      : message;
    const val = typeof message === "string" ? jsonChecker(message) : message;
    if (isArray(val) && val.length > 0) {
      return handleError(val[0]);
    }
    if (typeof val === "object" && Object.keys(val).length > 0) {
      const [item] = Object.keys(val);
      return handleError(val[item]);
    }
    if (typeof val === "string" && val.length > 0 && val.length < 200) {
      if (val.includes("Authentication")) {
        return `Authentication failed. Try re-login again.`;
      }
      return val;
    }
    return defaultMessage || `Something went wrong. Please try again later.`;
  } catch {
    return `Something went wrong. Please try again later.`;
  }
}

export default handleError;
