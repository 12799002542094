const theme = {
  breakpoints: ["576px", "768px", "992px", "1200px", "1400px"],
  colors: {
    text: "#333",
    background: "#fff",
    primary: "#2196f3",
    secondary: "#dddddd",
  },

  HFNReg: {
    minHeight: "100vh",
    width: "100%",
    background: "#fff",
    display: "flex",
    flexDirection: "column",

    ".header-bg": {
      height: "160px",
      position: "relative",
      background: "#6cbcfb",
      ".overlay": {
        height: "100%",
        width: "100%",
        backgroundColor: "#1ca0f2",
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "30px",
        color: "#fff",
        textAlign: "center",
        padding: "0 10px",
      },
    },

    "*": {
      margin: 0,
      border: 0,
      padding: 0,
      boxSizing: "border-box",
    },

    ".card": {
      borderRadius: "3px",
      boxShadow: "1px 2px 4px #d2d2d261",
      border: "1px solid #d2d2d261",
      background: "#fff",

      ".card-header": {
        padding: "20px",
        borderBottom: "1px solid #ececec",
        background: "#f1f1ff",
      },
      ".card-body": {
        padding: [0, "20px"],
      },
      ".card-title": {
        display: "flex",
        justifyContent: "space-between",
        alignitems: "center",
      },
    },

    ".info-box-wrapper": {
      marginBottom: "20px",
      ".info-box": {
        width: "45%",
        padding: "10px 12px",
        background: "#f1f1ff40",
        minWidth: "200px",
        borderLeft: "2px solid #f1f1ff",
        h4: {
          marginBottom: "8px",
        },
      },
    },

    ".register-btn-group": {
      display: "flex",
      justifyContent: "flex-end",
      button: {
        cursor: "pointer",
        a: {
          color: "#fff",
          textDecoration: "none",
        },
      },
    },

    ".form-section": {
      padding: "20px",
      maxWidth: "600px",
      margin: "0 auto",
      background: "#fff",
      h2: {
        marginBottom: "20px",
      },
      button: {
        cursor: "pointer",
      },
      ".ms-Label": {
        marginBottom: "10px",
      },
      ".ms-MessageBar--error": {
        marginBottom: "15px",
        padding: "5px",
      },
    },

    ".ms-TextField-wrapper": {
      margin: "15px 0",

      ".ms-TextField-field": {
        border: "1px solid #dedede",
        padding: "5px",
      },
    },

    ".ms-BasePicker-input": {
      border: "1px solid #dedede",
      padding: "5px",
    },

    ".text-right": {
      textAlign: "right",
    },

    ".badge": {
      marginTop: "10px",
      display: "inline-block",
      background: "#1ca0f2",
      borderRadius: "2px",
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: 500,
      color: "#717171",
      cursor: "pointer",

      "a , >span": {
        padding: "5px",
        textDecoration: "none",
        color: "#fff !important",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        svg: {
          marginRight: "4px",
          height: "18px",
          width: "18px",
        },
      },
    },

    ".badge.disabled": {
      cursor: "not-allowed !important",
      a: {
        pointerEvents: "none",
        background: "#b3b3b3",
        color: "#fff !important",
        svg: {
          fill: "#fff !important",
        },
      },
    },

    ".event-se-section": {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    ".breadcrumb": {
      ul: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "20px",
        listStyle: "none",
        li: {
          marginRight: "10px",
          a: {
            textDecoration: "none",
            color: "#2196f3",
          },
          ".separator": {
            margin: "0 5px",
          },
        },

        "li:last-child": {
          pointerEvents: "none",
          a: {
            color: "#cacaca",
          },
          ".separator": {
            display: "none",
          },
        },
      },
    },

    "button.secondary": {
      color: "#afafaf",
      background: "#efefef",
    },

    ".event-details-page": {
      ".info-box": {
        "@media only screen and (max-width:1400px)": {},
        "@media only screen and (max-width:767px)": {
          width: "50%",
        },
        "@media only screen and (max-width:567px)": {
          width: "100%",
        },
      },
    },

    ".register-social-link-group": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media only screen and (max-width:567px)": {
        flexWrap: "wrap",
        justifyContent: "center",
      },
    },

    ".social-link": {
      display: "flex",
      ul: {
        listStyle: "none",
        margin: "0 10px",
        display: "flex",
        li: {
          margin: "0 5px",
          svg: {
            height: "20px",
            width: "20px",
            cursor: "pointer",
          },
        },
      },
      "@media only screen and (max-width:567px)": {
        marginBottom: "30px",
      },
    },
  },
  RegDetails: {
    justifyContent: "space-between",
    flexBasis: "20%",
    flexWrap: "wrap",
    p: {
      minWidth: "20%",
    },
    h4: {
      minWidth: "20%",
    },
    ".badge-red": {
      background: "red",
      color: "#fff",
      marginRight: "15px",
      textAlign: "center",
      borderRadius: "4px",
      fontWeight: 600,
    },
    ".badge-green": {
      background: "green",
      color: "#fff",
      marginRight: "15px",
      textAlign: "center",
      borderRadius: "4px",
      fontWeight: 600,
    },
  },
  HFNRegHeader: {
    padding: "0 10px",
    background: "#fff",
    boxShadow: "0px 0px 10px #e0e0e0",
    display: "flex",
    alignitems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 888,
    ".logo-section": {
      display: "flex",
      alignItems: "center",

      ".logo-title": {
        paddingLeft: "15px",
        h3: {
          fontSize: "22px",
          fontWeight: 100,
        },
        a: {
          textDecoration: "none",
          color: "#4a4a61",
          mx: "12px",
          lineHeight: "26px",
          fontSize: "20px",
        },
      },
    },
  },

  HFNMainPage: {
    flexGrow: 2,
    paddingBottom: "45px",
    overflow: "auto",
    width: "85%",
    margin: "30px auto",
    padding: "0 10px",

    ".main-wrapper": {
      display: "flex",
      main: {
        width: "calc(100% - 300px)",
        "@media only screen and (max-width: 1200px)": {
          width: "calc(100% - 200px)",
        },
        "@media only screen and (max-width: 768px)": {
          width: "100%",
        },
      },
      ".sidebar": {
        width: "300px",
        paddingLeft: "20px",
        paddingTop: "25px",
        ".img-wrapper": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          img: {
            marginBottom: "25px",
            cursor: "pointer",
          },
          ".card": {
            maxWidth: "80%",
            minWidth: "80%",
            margin: "0 auto",
            boxShadow: "2px 2px 12px #00000026",
            borderRadius: "4px",
            width: ["100%", "50%"],
            p: "15px",
          },
        },
        "@media only screen and (max-width: 1200px)": {
          width: "200px",
        },
        "@media only screen and (max-width: 768px)": {
          display: "none",
        },
      },
    },

    ".main-full-wrapper": {
      width: "100% !important",
    },

    "@media only screen and (max-width: 600px)": {
      width: "99%",
    },
  },

  HFNRegFooter: {
    padding: "16px 20px",
    maxWidth: "80%",
    margin: "0 auto",
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: ["column", "column", "row"],
    gap: "10px",
    p: {
      textAlign: "center",
      fontSize: "12.5px",
      color: "#c9c8c8",
    },
    a: {
      textDecoration: "none",
      mr: 3,
    },
  },
  HFNDropdown: {
    position: "relative",
    alignSelf: "center",
    pb: 2,
    height: "100%",
    border: "none",
    // ":hover": { borderBottom: "2px solid #1ca0f2" },
    ".hoverDropdown": {
      // display: "none",
      flexDirection: "column",
      position: "absolute",
      top: "100%",
      bg: "#fff",
      boxShadow: "2px 2px 8px #00000078",
      left: "-10px",
      width: "200px",
      transition: "all 2s",
      span: {
        p: "12px",
        cursor: "pointer",
        ":hover": { bg: "#1ca0f229", color: "#1ca0f2" },
      },
      a: {
        p: "12px",
        cursor: "pointer",
        textDecoration: "none",
        color: "#4a4a61",
        ":hover": { bg: "#1ca0f229", color: "#1ca0f2" },
      },
    },
    "#nav-hover": {
      bg: "transparent",
      lineHeight: "100%",
      height: "100%",
    },
  },
  BadgeStyle: {
    mr: 2,
    display: "inline-block",
    bg: "#17a2b8",
    color: "#fff",
    p: "0.2em 0.4em",
    fontSize: "75%",
    fontWeight: 700,
    textAlign: "center",
    lineHeight: 1,
    borderRadius: "0.25rem",
    transition: "all .15s ease-in-out",
  },
  HFNEventList: {
    ".page-title": {
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
      // alignItems: "center",
      borderRadius: "3px",
      marginBottom: "20px",
      h3: {
        fontSize: "20px",
        fontWeight: 500,
        paddingRight: "10px",
        paddingBottom: "10px",
      },
      form: {
        display: "flex",
        width: "100%",
        // flexWrap: "wrap",
        maxWidth: "100%",
        justifyContent: "right",
        gap: "6px",
        mt: ["8px", 0],
        ".search , .select": {
          width: ["150px", "180px"],
        },
        ".search": {
          width: ["135px", "160px"],
        },
      },
    },

    ul: {
      listStyle: "none",

      li: {
        margin: "20px 0",
        background: "#fff",
        borderRadius: "3px",
        boxShadow: "1px 2px 4px #d2d2d261",

        display: "flex",

        ".overlay-img": {
          position: "relative",
          maxWidth: "300px",
          ".overlay": {
            position: "absolute",
            height: "100%",
            width: "100%",
          },
          ".img": {
            lineHeight: 0,
            height: "100%",
            width: "100%",
            img: {
              height: "100%",
              width: "100%",
              objectFit: "cover",
            },
          },

          "@media only screen and (max-width: 1200px)": {
            width: "100%",
            maxWidth: "200px",
          },

          "@media only screen and (max-width: 600px)": {
            width: "100%",
            maxWidth: "100%",
          },
        },

        ".event-info": {
          padding: "20px",
          width: "calc(100% - 300px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          ".title-wrapper": {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            span: {
              minWidth: "120px",
              textAlign: "right",
              paddingLeft: "10px",
            },
          },

          h3: {
            fontWeight: 500,
            fontSize: "18.5px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },

          ".event-se-date": {
            margin: "10px 0",
            fontSize: "14px",
            span: {
              marginRight: "5px",
            },
          },

          ".event-preview": {
            margin: "10px 0",
          },

          "@media only screen and (max-width: 1200px)": {
            width: "calc(100% - 200px)",
          },
          "@media only screen and (max-width: 600px)": {
            width: "100%",
          },
        },

        "@media only screen and (max-width: 600px)": {
          flexWrap: "wrap",
        },
      },
    },
  },
};

export default theme;
